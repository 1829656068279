<template>
  <b-modal v-model="showModal" ref="modal-pin" @hidden="hide" centered>
    <template #modal-header>
      <div class="ft-black">Generate Pin</div>
    </template>

    <div class="font-weight-bold text-center">Your Pin is</div>

    <div class="d-flex justify-content-center text-center align-items-center">
      <div
        v-for="(item, index) in pin"
        :key="index"
        class="pin font-weight-bold mx-2 mt-3"
      >
        {{ item }}
      </div>
      <font-awesome-icon
        :icon="['fas', 'copy']"
        class="icon-copy mt-3 ml-2"
        @click="copyPin"
      />
    </div>
    <template #modal-footer>
      <div class="content-between">
        <b-button class="btn border-btn" @click="hide"> Cancel </b-button>
        <b-button class="submit-btn" @click="hide"> Confirm </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
      pin: "",
    };
  },
  methods: {
    async show() {
      const res = await this.axios(`/user/generatePin`);
      this.pin = res.data.detail;
      this.showModal = true;
    },
    hide() {
      this.pin = "";
      this.showModal = false;
    },
    copyPin() {
      let _pin = this.pin;
      navigator.clipboard.writeText(_pin);
      this.successAlert(`${_pin}`, "You just copied");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  display: block;
}

.pin {
  font-size: 30px;
}

.icon-copy {
  font-size: 25px;
  color: var(--primary-color);
  cursor: pointer;
}
</style>
